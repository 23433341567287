@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-Bold.eot");
  src: local("Euclid Circular A Bold"), local("EuclidCircularA-Bold"),
    url("./assets/font/EuclidCircularA-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Bold.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Bold.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-Light.eot");
  src: local("Euclid Circular A Light"), local("EuclidCircularA-Light"),
    url("./assets/font/EuclidCircularA-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Light.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Light.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-BoldItalic.eot");
  src: local("Euclid Circular A Bold Italic"),
    local("EuclidCircularA-BoldItalic"),
    url("./assets/font/EuclidCircularA-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-BoldItalic.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-BoldItalic.woff") format("woff"),
    url("./assets/font/EuclidCircularA-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-LightItalic.eot");
  src: local("Euclid Circular A Light Italic"),
    local("EuclidCircularA-LightItalic"),
    url("./assets/font/EuclidCircularA-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-LightItalic.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-LightItalic.woff") format("woff"),
    url("./assets/font/EuclidCircularA-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-Regular.eot");
  src: local("Euclid Circular A Regular"), local("EuclidCircularA-Regular"),
    url("./assets/font/EuclidCircularA-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Regular.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Regular.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-SemiBold.eot");
  src: local("Euclid Circular A SemiBold"), local("EuclidCircularA-SemiBold"),
    url("./assets/font/EuclidCircularA-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-SemiBold.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-SemiBold.woff") format("woff"),
    url("./assets/font/EuclidCircularA-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-Medium.eot");
  src: local("Euclid Circular A Medium"), local("EuclidCircularA-Medium"),
    url("./assets/font/EuclidCircularA-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Medium.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Medium.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-Italic.eot");
  src: local("Euclid Circular A Italic"), local("EuclidCircularA-Italic"),
    url("./assets/font/EuclidCircularA-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-Italic.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-Italic.woff") format("woff"),
    url("./assets/font/EuclidCircularA-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-SemiBoldItalic.eot");
  src: local("Euclid Circular A SemiBold Italic"),
    local("EuclidCircularA-SemiBoldItalic"),
    url("./assets/font/EuclidCircularA-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-SemiBoldItalic.woff") format("woff"),
    url("./assets/font/EuclidCircularA-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("./assets/font/EuclidCircularA-MediumItalic.eot");
  src: local("Euclid Circular A Medium Italic"),
    local("EuclidCircularA-MediumItalic"),
    url("./assets/font/EuclidCircularA-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/font/EuclidCircularA-MediumItalic.woff2") format("woff2"),
    url("./assets/font/EuclidCircularA-MediumItalic.woff") format("woff"),
    url("./assets/font/EuclidCircularA-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

.modal {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(81, 255, 0, 0.14);
  transition: all 0.4s;
}

.modal.open {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s;
}

.modal__content {
  border-radius: 4px;
  position: relative;
  width: 500px;
  max-width: 90%;
  background: #fff;
  padding: 2em;
}
.modal__title {
  font-size: 1.3rem;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 10px;
  color: #585858;
  text-decoration: none;
  font-size: 2rem;
  cursor: pointer;
}

.otp-code__input {
  border-radius: 0.5rem;
  font-family: monospace;
  height: 50px;
  border: 1px solid #ccc;
  font-size: 2rem;
  text-align: center;
  transition: all 0.2s ease;
  color: #222;
  width: 3rem !important;
  margin: 0 0.5rem;
}

.myCursor {
  cursor: pointer;
}
.file_upload {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.file_upload #file {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
}

button,
button:focus,
input,
input:focus,
*:focus {
  outline: none !important;
  box-shadow: none !important;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0 !important;
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}
.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebf1ff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #fbb100;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #65d133;
}

.textTrunc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.textTrunc2 {
  -webkit-line-clamp: 2;
}
.textTrunc3 {
  -webkit-line-clamp: 3;
}
.textTrunc4 {
  -webkit-line-clamp: 4;
}
.textTrunc5 {
  -webkit-line-clamp: 5;
}
.textTrunc10 {
  -webkit-line-clamp: 10;
}
.style-module_simpleStarRating__nWUxf svg {
  display: inline !important;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}
body {
  top: 0 !important;
}

#google_translate_element {
  padding: 0 !important;
}

/* Customize the Google Translate Element */
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-te-menu-value {
  padding: 4px !important; /* Adjust padding */
  font-size: 16px !important; /* Change font size */
}

/* Change the dropdown arrow color */
.goog-te-combo {
  color: #333 !important;
}

/* Change the background color of the dropdown menu */
.goog-te-menu-list {
  background-color: #fff !important;
  padding: 0 !important;
}

/* Change the text color of the dropdown items */
.goog-te-menu-item {
  color: #333 !important;
  padding: 0 !important;
}

/* Customize the hover effect of the dropdown items */
.goog-te-menu2-item:hover {
  background-color: #f0f0f0 !important;
}
.goog-te-gadget-icon {
  display: none;
  padding: 0 !important;
  margin: 0 !important;
}

.VIpgJd-ZVi9od-xl07Ob-lTBxed > :nth-child(3) {
  display: none;
  padding: 0 !important;
}
.VIpgJd-ZVi9od-xl07Ob-lTBxed > :nth-child(1) {
  display: none;
}
.VIpgJd-ZVi9od-xl07Ob-lTBxed img {
  display: none;
}

.goog-te-gadget-simple {
  background-color: transparent !important;
  border: 0 !important;
  font-size: 10pt;
  font-weight: 800;
  display: inline-block;
  padding: 0 !important;
  cursor: pointer;
  height: 40px !important;
  overflow: visible;
}

.goog-te-gadget {
  padding: 0 !important;
  margin: 0 !important;
}

.goog-te-gadget-simple span {
  color: #65d133 !important;
}

.sef {
  background-color: #4d8b33;
}