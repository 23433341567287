@tailwind base;

/* @layer base {
  html {
    @apply text-neutral-800;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
} */
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Euclid Circular A';
  background: #F7F8F4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient {
  background-image: linear-gradient(170deg, #4d8b33, #4d8b33, #FFF720);
}

.gradient2 {
  background-image: linear-gradient(130deg, #4d8b33, #4d8b33, #FFF720);
}

.gradien {
  background-image: linear-gradient(130deg, #4d8b33, #4d8b33, #416132);
}